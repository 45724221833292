import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrochureComponent } from './pages/brochure/brochure.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './login/login.component';
import { LoginScreenComponent } from './login/login-screen/login-screen.component';
import { MfaLoginComponent } from './login/mfa-login/mfa-login.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { EmailVerifyComponent } from './login/email-verify/email-verify.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '404',
    component: ErrorComponent,
  },
  {
    path: ':configId',
    component: BrochureComponent,
  },
  {
    path: '**',
    redirectTo: '/add-your-code-here',
  },
];

// only add login routes when authentication is required (tst)
if (environment.enableAuthentication) {
  routes.unshift({
    path: 'login',
    component: LoginComponent,
    children: [
      { path: '', component: LoginScreenComponent },
      { path: 'mfa', component: MfaLoginComponent },
      { path: 'reset', component: PasswordResetComponent },
      { path: 'verify', component: EmailVerifyComponent },
    ],
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

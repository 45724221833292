import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { EmissionsModalComponent } from './components/emissions-modal/emissions-modal.component';
import { HeroComponent } from './components/hero/hero.component';
import { ImageComponent } from './components/image/image.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SectionSeparatorComponent } from './components/section-separator/section-separator.component';
import { SocialComponent } from './components/social/social.component';
import { VideoComponent } from './components/video/video.component';
import { GridComponent } from './layout/grid/grid.component';
import { BrochureComponent } from './pages/brochure/brochure.component';
import { ErrorComponent } from './pages/error/error.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginComponent } from './login/login.component';
import { LoginScreenComponent } from './login/login-screen/login-screen.component';
import { MfaLoginComponent } from './login/mfa-login/mfa-login.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { EmailVerifyComponent } from './login/email-verify/email-verify.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    ImageComponent,
    CarouselComponent,
    VideoComponent,
    HeroComponent,
    GridComponent,
    BrochureComponent,
    ErrorComponent,
    LoaderComponent,
    LightboxComponent,
    EmissionsModalComponent,
    SectionSeparatorComponent,
    SocialComponent,
    LoginComponent,
    LoginScreenComponent,
    MfaLoginComponent,
    PasswordResetComponent,
    EmailVerifyComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTooltipModule,
    ServiceWorkerModule.register('sw.js', {
      enabled: environment.enableAuthentication,
      registrationStrategy: 'registerImmediately',
    }),
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export const environment = {
  production: false,
  enableAuthentication: true,
  apiUrl: 'https://api.evecp-tst.bmw.cloud',
  getAssetsEndpoint: '/v4/brochure/',
  cognitoConfig: {
    userPoolId: '', // will be overriden by CI
    clientId: '', // will be overriden by CI
  },
};
